.App{
  height: 100VH;
  width: 100VW;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  gap: 3vh;
  background-color: #444;
  color: aliceblue;
}

.App::-webkit-scrollbar{
  display: none;
}