:root {
    /* Primary Colors for Dark Mode (Default) */
    --primary-background: #1A1A1A;   /* Dark background */
    --primary-text: #EAEAEA;         /* Light gray text */
    --primary-link: #4BA3FF;         /* Light blue links and buttons */
    
    /* Secondary and Accent Colors */
    --secondary-background: #2A2A2A; /* Slightly lighter dark background */
    --border-color: #444444;          /* Dark gray for borders and dividers */
    --accent-dark: #343A40;           /* Darker accent */
    --accent-light: #3A3A3A;          /* Lighter accent for subtle backgrounds */
  
    /* Alert/Status Colors */
    --success-color: #28A745;         /* Green for success messages */
    --error-color: #DC3545;           /* Red for error messages */
    --warning-color: #FFC107;         /* Yellow for warnings */
    --info-color: #17A2B8;            /* Cyan for informational messages */
  
    /* Light Mode Overrides */
    --light-primary-background: #FFFFFF; /* Light background */
    --light-primary-text: #333333;       /* Dark gray text */
    --light-primary-link: #007BFF;       /* Blue links and buttons */
    --light-secondary-background: #F8F9FA; /* Light gray for secondary backgrounds */
    --light-border-color: #E0E0E0;          /* Light gray for borders and dividers */
  }
  