.footerContainer{
    width: 100%;
    flex-direction: column;
    display: flex;
    background-color: black;
    padding: 2vw;
    gap: 2vw;
    text-align: center;
}

span{
    align-self: center;
}

.subContainer{
    flex-direction: column;
    display: flex;
    gap:1vh;
    justify-content: flex-start;
}

.separator{
    height: 100%;
    width: 1px;
    align-self: center;
    background-color: rgba(255, 253, 253, 0.285);
}

a{
    flex-direction: row;
    display: flex;
    gap: 3px;
    max-height: 20px;
}

p{
    color: aliceblue;
    margin: auto;
}
.logo{
    width: 20px;
    height: 20px;
}