.overview{
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    height: 100%;
    background-color: #444;
    color: aliceblue;
    height: max-content;
    .title{
        width: 100%;
        span{
            align-self: center;
            font-size:  calc(1.1*(5vh + 1vw));
            font-weight: bold;
        }   
        p{
            align-self: center;
            font-size: calc(0.65*(5vh + 1vw));
        }
    }
    .separator{
        width: 80vw;
        height: 3px;
        align-self: center;
        background-color: rgba(0, 0, 0, 0.5);

    }
    .container {
        width: 85vw;
        min-height: 40vh;
        max-height: 65vh;
        background-color: #444;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        border-radius: 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        overflow: hidden; 
        position: relative;
    }
    
    .container img {
        object-fit: cover;
        min-width: 20%;
        max-width: 30%;
        height: 100%;
        z-index: 1;
        position: relative; 
        flex-shrink: 0; 
    }
    
    .text_overview {
        z-index: 0;
        overflow-y: auto;
        text-align: justify;
        flex: 2;
        padding: 20px;
        font-size: calc(0.85*(2vh + 1vw));
        align-self: flex-start;
        height: 90%;
        box-sizing: border-box;
        scrollbar-width: 0px;
    }

    
    
    .container-2{
        width: 81vw;
        padding: 2vw;
        min-height: 40vh;
        background-color: #444;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        overflow: hidden;
        .language-bar {
            margin-bottom: 10px;
        }
        
        .language-bar span {
            margin-right: 10px;
            font-weight: bold;
        }
        .span{
            font-size: 1rem;
        }
        
        .progress {
            background-color: #eee;
            border-radius: 10px;
            border: 1px solid #ccc;
            height: 20px;
            width: 90%;
            overflow: hidden;
        }
        
        .progress-bar {
            background-color: #4CAF50;
            height: 100%;
            display: flex;
            align-items: center; 
            justify-content: center; 
            text-align: center; 
            white-space: nowrap; 
        }
    }
}

.sectionTitle{
    align-self: center;
    font-size: calc(0.65*(5vh + 1vw));
}
.footer{
    align-self: flex-end;
    width: 100%;
    height: 10%;
    background-color: #4CAF50;
}

.carousel{
    display: flex;
    flex-direction: row;
    height: 45vh;
    img{
        height: 40px;
        width: 40px;
    }
}
.leftNavigate{
    flex: 1;
    height: 100%;
    background: none;
    border: none;
}
.rightNavigate{
    flex: 1;
    height: 100%;
    background: none;
    border: none;
}

@keyframes fadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.carouselContent{
    flex: 10;
    width: 85vw;
    max-height: 45vh;
    background-color: #444;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow: hidden; 
    img{
        
        flex: 1;
        width: 50%;
        height: auto;
        max-height: 100%;
    }
    .carouselInfo{
        flex: 3;
    }
    .tagsContainer{
        display: flex;
        flex-direction: row;
        gap: 5px;
        width: 100%;
        justify-content: center;
    }
    .tags{
        border-radius: 14px;
        border: black 1px solid;
        padding: 0.65vh 2vw 0.65vh 2vw;
        color: black;
        background-color:yellow;
    }
    .description{
        display: none;
    }
}

.carouselContent.animate{
    animation: fadeInSlideUp 0.8s ease-in;
}

@media(max-width: 600px){
    .carousel{
        height: max-content;
    }
    .carouselContent{
        flex-direction: column;
        max-height: max-content;
        img{
            width: 100%;
            max-height: 20%;
            flex: 1;
        }
        .carouselInfo{
            flex:3
        }
    }
    .description{
        display: none;
    }
}